<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-11">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <h3 class="mb-0">Chamados</h3>
        </div>
        <div class="card-body">
          <b-table
              :fields="['tipo','prioridade','assunto','status','acoes']"
              :items="lista_chamados"
              :per-page="perPage"
              :current-page="currentPage"
              id="aviso-table"
              class="table table-head-custom table-vertical-center table-head-bg table-borderless"
              show-empty
              small
              empty-text="Nenhum registro encontrado!"
          >
         
                 <template #head(acoes)><span></span></template>
                 <template #cell(tipo)="data">
          <span
            class="label label-lg label-inline px-2 pb-2 pt-3"
            :class="{
              'label-light-info': data.item.tipo === 1,
              'label-light-primary': data.item.tipo === 0,
            }"
          >
            {{ data.item.tipo === 1 ? "Técnico/Sistema" : "Financeiro/administração" }}
          </span>
          </template>
          <template #cell(prioridade)="data">
          <span
            class="label label-lg label-inline px-2 pb-2 pt-3"
            :class="{
              'label-light-success': data.item.prioridade === 3,
              'label-light-danger': data.item.prioridade === 1,
              'label-light-warning': data.item.prioridade === 2,
            }"
          >
            {{   data.item.prioridade === 1 ? "Alta" :  data.item.prioridade === 2 ? "media" : "baixa"}}
        

          </span>
          </template>
          <template #cell(status)="data">
          <span
            class="label label-lg label-inline px-2 pb-2 pt-3"
            :class="{
              'label-light-success': data.item.status === 1,
              'label-light-primary': data.item.status === 2,
            }"
          >
            {{   data.item.status === 1 ? "fechado" :  data.item.status === 2 ? "aberto" : ""}}
        

          </span>
          </template>

                    <template #cell(acoes)="{item}">
              <div class="text-right w-100">
       
                <button
                 v-show="lista_permissoes_filial.u_Chamados"
                    @click="atualizar(item)"
                     class="btn btn-icon btn-light btn-sm mx-1"
                    v-b-tooltip.hover title="Editar registro"
                >
                  <i class="far fa-edit text-primary"></i>
                </button>

                <button
                 v-show="lista_permissoes_filial.lock_Chamados"
                    @click="confirm(item)"
                 class="btn btn-icon btn-light btn-sm mx-1"
                    v-b-tooltip.hover title="Bloquear/Desbloquear registro"
                >
                  <i class="fas fa-unlock-alt text-danger"></i>
                </button>
                <button
                 v-show="lista_permissoes_filial.c_Chamados"
                    @click="entrar(item)"
                 class="btn btn-icon btn-light btn-sm mx-1"
                    v-b-tooltip.hover title="Acessar Chamado"
                >
                  <i class="fas fa-arrow-alt-circle-up text-warning"></i>
                </button>
                         </div>
            </template>
          </b-table>
          <b-pagination
              v-model="currentPage"
              :total-rows="lista_chamados.length"
              :per-page="perPage"
              aria-controls="aviso-table"
          >
          </b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/store/breadcrumbs.module";
import dateFormat from "@/core/mixins/dateFormat";

export default {
  mixins:{dateFormat},
  data() {
    return {
         currentPage: 1,
      perPage: 10,

      auto
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Chamados"}]);
  },
  created() {
    this.listar_chamados();
  },
  computed: {
    lista_chamados() {
      return this.$store.state.chamados.lista_chamados;
    },
    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    mensagem_alert() {
      return this.$store.state.chamados.mensagem_alert;
    },
  },
  methods: {
    async listar_chamados() {
        await this.$store.dispatch("chamados/listar_chamados").finally(() => {
            this.$store.dispatch("configEmpresa/atualizar", "");
      });
    },
    atualizar(value) {
      this.$router.push({name: "createChamados"});
      this.$store.dispatch("configEmpresa/atualizar", value);
    },
    entrar(value) {
      this.$router.push({name: "chamado", params: { chamado_id: value.id} });
      this.$store.dispatch("configEmpresa/atualizar", value);
    },
    
    async confirm(value) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação excluir um chamado no sistema?`,
        button: {
          no: "Não",
          yes: "Sim",
        },
        callback: (confirm) => {
          if (confirm) {
            this.bloquear(value);
          }
        },
      });
    },
    async bloquear(value) {
      await this.$store.dispatch("chamados/bloquear_chamados", value);
      this.alertMessage(this.mensagem_alert.tipo, this.mensagem_alert.message);
    },
    alertMessage(tipo, mensagem) {
      this.$fire({
        title: "Aviso",
        text: mensagem,
        type: tipo,
        timer: 3000,
      }).then((r) => {
        console.log(r.value);
      });
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>