var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row justify-content-center align-items-md-center"},[_c('div',{staticClass:"col-md-11"},[_c('div',{staticClass:"card full-height bg-ligth"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('b-table',{staticClass:"table table-head-custom table-vertical-center table-head-bg table-borderless",attrs:{"fields":['tipo','prioridade','assunto','status','acoes'],"items":_vm.lista_chamados,"per-page":_vm.perPage,"current-page":_vm.currentPage,"id":"aviso-table","show-empty":"","small":"","empty-text":"Nenhum registro encontrado!"},scopedSlots:_vm._u([{key:"head(acoes)",fn:function(){return [_c('span')]},proxy:true},{key:"cell(tipo)",fn:function(data){return [_c('span',{staticClass:"label label-lg label-inline px-2 pb-2 pt-3",class:{
            'label-light-info': data.item.tipo === 1,
            'label-light-primary': data.item.tipo === 0,
          }},[_vm._v(" "+_vm._s(data.item.tipo === 1 ? "Técnico/Sistema" : "Financeiro/administração")+" ")])]}},{key:"cell(prioridade)",fn:function(data){return [_c('span',{staticClass:"label label-lg label-inline px-2 pb-2 pt-3",class:{
            'label-light-success': data.item.prioridade === 3,
            'label-light-danger': data.item.prioridade === 1,
            'label-light-warning': data.item.prioridade === 2,
          }},[_vm._v(" "+_vm._s(data.item.prioridade === 1 ? "Alta" : data.item.prioridade === 2 ? "media" : "baixa")+" ")])]}},{key:"cell(status)",fn:function(data){return [_c('span',{staticClass:"label label-lg label-inline px-2 pb-2 pt-3",class:{
            'label-light-success': data.item.status === 1,
            'label-light-primary': data.item.status === 2,
          }},[_vm._v(" "+_vm._s(data.item.status === 1 ? "fechado" : data.item.status === 2 ? "aberto" : "")+" ")])]}},{key:"cell(acoes)",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"text-right w-100"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.lista_permissoes_filial.u_Chamados),expression:"lista_permissoes_filial.u_Chamados"},{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-icon btn-light btn-sm mx-1",attrs:{"title":"Editar registro"},on:{"click":function($event){return _vm.atualizar(item)}}},[_c('i',{staticClass:"far fa-edit text-primary"})]),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.lista_permissoes_filial.lock_Chamados),expression:"lista_permissoes_filial.lock_Chamados"},{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-icon btn-light btn-sm mx-1",attrs:{"title":"Bloquear/Desbloquear registro"},on:{"click":function($event){return _vm.confirm(item)}}},[_c('i',{staticClass:"fas fa-unlock-alt text-danger"})]),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.lista_permissoes_filial.c_Chamados),expression:"lista_permissoes_filial.c_Chamados"},{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-icon btn-light btn-sm mx-1",attrs:{"title":"Acessar Chamado"},on:{"click":function($event){return _vm.entrar(item)}}},[_c('i',{staticClass:"fas fa-arrow-alt-circle-up text-warning"})])])]}}])}),_c('b-pagination',{attrs:{"total-rows":_vm.lista_chamados.length,"per-page":_vm.perPage,"aria-controls":"aviso-table"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header"},[_c('h3',{staticClass:"mb-0"},[_vm._v("Chamados")])])}]

export { render, staticRenderFns }